.error {
  /* border: 1px solid red; */
}

.enabled {
}

input.currency {
  text-align: right;
}

.disabled {
  display: none;
}

.ProseMirror {
  font-family: "Sora";
}

::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}
