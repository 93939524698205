html,
body {
  display: block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  min-height: 100%;
}

body.no-scroll {
  position: fixed;
  overflow: hidden;
}

#root,
.App {
  padding: 0;
  margin: 0;
  min-width: 100%;
  min-height: 100%;
}

body {
  font-family: "Sora", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Sora", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.pointer {
  cursor: pointer;
}

#conversations-wrap {
  display: none;
}

.flex {
  display: flex;
}

#product-page-detail:has(#product-page-detail-suggested-prompts-container) {
  max-width: none;
}
